import * as React from "react"
import styled, { css } from "styled-components"

const Container = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;  
  display: flex;
  align-items: center;
  border-right: 1px solid #edeef5;
  
  ${(props: { isTitle: boolean }) => css`
    justify-content: ${props.isTitle ? "flex-start" : "center" };
    text-align: ${props.isTitle ? "left" : "center" };
  `}

  * {
    margin: 0;
  }

  &.last {
    border-right: none;
  }
`

interface TableColumnProps {
  text?: React.ReactNode
  isTitle?: boolean
  width: number
  className?: string
}

export default class TableColumn extends React.Component<TableColumnProps> {
  render() {
    const { className, isTitle, text, width, children } = this.props

    return (
      <Container
        isTitle={isTitle || false}
        className={`col-${ isTitle ? "5 left" : "7" } col-md-${ width } ${ className }`}>
        {text ? (
          <p>{text}</p>
        ) : children}
      </Container>
    )
  }
}