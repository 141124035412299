import * as React from "react"
import { Tab, TabsContainer } from "./styled"

interface TabsProps {
  titles: React.ReactNode[]
  content: React.ReactNode[]
}

interface TabsState {
  currentIndex: number
}

export default class Tabs extends React.Component<TabsProps, TabsState> {
  state: TabsState = {
    currentIndex: 0
  }

  updateCurrentIndex = (index: number) => () => {
    this.setState(prevState => ({
      currentIndex: index
    }))
  }

  render() {
    const { titles, content } = this.props
    const { currentIndex } = this.state

    return (
      <>
        <TabsContainer className="container-fluid">
          <div className="row">
            {titles.map((title, index) => {
              return (
                <Tab
                  key={index}
                  className={`col ${currentIndex === index && "active"}`}
                  onClick={this.updateCurrentIndex(index)}>
                  {title}
                </Tab>
              )
            })}
          </div>
        </TabsContainer>

        {content[currentIndex]}
      </>
    )
  }
}