import { IconDefinition } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as React from "react"
import styled, { css } from "styled-components"
import { H4 } from "../design-system"

const IconContainer = styled.div`
  width: 32px;
  height: 32px;
`

const LargeIconContainer = styled(IconContainer)`
  width: 48px;
  height: 48px;
`

interface ContainerProps {
  border: boolean,
  background: string,
  padded: boolean,
  color: string
  fullHeight: boolean
}

const Container = styled.div`
  ${(props: ContainerProps) => css`
    background-color: ${props.background};
    border: ${props.border ? "1px solid #ececec" : "none"};
    padding: ${props.padded ? "24px" : "0"};
    border-radius: 6px;
    z-index: 1;
    height: ${props.fullHeight ? "100%" : "auto"};
    margin-top: 24px;
    
    * {
      color: ${props.color === "#252525" ? "auto" : props.color};
    }

    h4 {
      line-height: 48px;
      margin: 0;
    }

    a {
      color: #4c0099;
      text-decoration: underline;
    }
  `}
`

interface FeatureProps {
  icon: IconDefinition
  largeIcon?: boolean
  fullHeight?: boolean
  title: React.ReactNode
  description?: React.ReactNode
  border?: boolean
  padded?: boolean
  background?: string
  color?: string
}

export default class Feature extends React.Component<FeatureProps> {
  render() {
    const {
      largeIcon = false,
      icon,
      title,
      description,
      background = "#fff",
      border = true,
      padded = true,
      color = "#252525",
      fullHeight = false
    } = this.props

    const IconHolder = largeIcon === true ? LargeIconContainer : IconContainer

    return (
      <Container
        className="container-fluid"
        padded={padded}
        border={border}
        background={background}
        color={color}
        fullHeight={fullHeight}>
        <div className="row">
          <div className="col-3 col-sm-4 col-md-2">
            <IconHolder className="d-flex justify-content-center align-items-center">
              <FontAwesomeIcon
                icon={icon}
                size={largeIcon === true ? "2x" : "lg"}
                color={color === "#252525" ? "#4c0099" : color} />
            </IconHolder>
          </div>

          <div className="col-9 col-sm-7 col-md-10">
            <H4>{title}</H4>

            {description ? <div className="d-none d-md-block">{description}</div> : null}
          </div>

          <div className="col-12 d-md-none">
            {description ? <p>{description}</p> : null}
          </div>
        </div>
      </Container>
    )
  }
}