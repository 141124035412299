import { Link } from "gatsby"
import * as React from "react"
import styled from "styled-components"
import { ColumnHead } from "."
import Button from "../button"

const Container = styled.div`
  background-color: #F6F7FC;
`

const Row = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: center;

  &.head-right {
    border-right: 2px solid #fff;
  }

  button {
    margin-top: 24px;
    width: 100%;
  }
`

const isExternalLink =
  (link: string) =>
    link.indexOf("http://") === 0 ||
    link.indexOf("https://") === 0

interface TableHeaderProps {
  currentIndex: number
  columns: ColumnHead[]
}

export default class TableHeader extends React.Component<TableHeaderProps> {
  render() {
    const { columns, currentIndex } = this.props
    const columnWidth = Math.floor((12 - (columns.length === 3 ? 3 : 2)) / columns.length)

    return (
      <Container className="row">
        {/* Empty column head */}
        <Row className={`col-5 col-md-${columns.length === 3 ? 3 : 2} head-right`} />

        {columns.map((column, index) => {

          const columnBorderClass = index === columns.length - 1 ? "" : "head-right"
          const columnClasses = index === currentIndex ?
            `d-block d-md-block ${columnBorderClass}` :
            `d-none d-md-block ${columnBorderClass}`
          return (
            <Row
              key={index}
              className={`col-7 col-md-${columnWidth} ${columnClasses}`}>
              <h4>{column.title}</h4>
              <p className="subtitle">{column.description}</p>

              {column.link ?
                isExternalLink(column.link) ? (
                  <a
                    href={column.link}>
                    <Button title={column.linkTitle!} />
                  </a>
                ) : (
                    <Link to={column.link}>
                      <Button title={column.linkTitle!} />
                    </Link>
                  ) : null}
            </Row>
          )
        })}
      </Container>
    )
  }
}