import styled from "styled-components"

export const SectionContainer = styled.div`
  /* border: 1px solid black; */
`

export const Row = styled.div`
  overflow: visible;
`

export const Column = styled.div`
  overflow: visible;
`