import * as React from "react"
import styled, { css } from "styled-components"

const getContainerProps = (props: { image: any, hideResponsiveBg: boolean }) => {

  if (Array.isArray(props.image)) {
    return css`
      position: relative;
      background-image: ${props.image[0]};

      @media only screen and (max-width: 1440px) {
        background-image: ${props.image[1]};
      }

      @media only screen and (max-width: 1024px) {
        background-image: ${props.image[2]};
      }

      @media only screen and (max-width: 768px) {
        background-image: ${ props.hideResponsiveBg ? "none" : props.image[3]};
      }

      @media only screen and (max-width: 576px) {
        background-image: ${props.hideResponsiveBg ? "none" : props.image[4]};
      }
    `
  }

  return css`
    position: relative;
    background-image: ${props.image};
    background-repeat: no-repeat;

    @media only screen and (max-width: 767.98px) {
      background-image: ${ props.hideResponsiveBg ? "none" : props.image};
    }
  `
}

const Container = styled.div`
  ${getContainerProps};
`

interface BackgroundContainerProps {
  image: any
  classes?: string
  style?: React.CSSProperties
  hideBackgroundOnMobile?: boolean
}

export default class BackgroundContainer extends React.Component<BackgroundContainerProps> {
  render() {
    const {
      image,
      classes,
      style,
      hideBackgroundOnMobile = false,
      children
    } = this.props

    return (
      <Container
        className={classes}
        style={style}
        image={image}
        hideResponsiveBg={hideBackgroundOnMobile}
      >
        {children}
      </Container>
    )
  }
}