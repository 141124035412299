import {
  faAnalytics,
  faChartNetwork,
  faCheck,
  faCommentsAlt,
  faHandshakeAlt,
  faLineColumns,
  faMegaphone,
  faUserLock,
  faWandMagic
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import hero from "assets/images/pricing-hero.png"
import hero2x from "assets/images/pricing-hero@2x.png"
import hero3x from "assets/images/pricing-hero@3x.png"
import hero4x from "assets/images/pricing-hero@4x.png"
import hero5x from "assets/images/pricing-hero@5x.png"
import BackgroundContainer from "components/bg-container"
import Button from "components/button"
import Card from "components/card"
import { A, H1, H2, H3, H4, P } from "components/design-system"
import Feature from "components/feature"
import List from "components/list"
import Section, { Column } from "components/new-section"
import Table from "components/table"
import Tabs from "components/tabs"
import { studioLinkPrefix } from "config/link"
import { locale } from "config/locale"
import { Link } from "gatsby"
import * as React from "react"
import { FormattedMessage } from "react-intl"
import Layout from "templates/layout"

export default class Pricing extends React.Component {
  render() {
    return (
      <Layout title="Pricing | Stackchat">

        {/* Hero */}
        <BackgroundContainer
          classes="N0-bg"
          image={[
            `url('${ hero5x }')`,
            `url('${ hero4x }')`,
            `url('${ hero3x }')`,
            `url('${ hero2x }')`,
            `url('${ hero }')`
          ]}
          style={{
            backgroundSize: "cover",
            backgroundPosition: "bottom 0 right 0"
          }}
        >
          <Section partition="full-width" classes="space-inner-top-10 space-inner-bottom-10">
            <Column className="col-12 col-md-12">
              <H2 className="N0 space-outer-top-8">
                <FormattedMessage id="pricing.section1.heading" />
              </H2>

              <P className="N0 space-outer-top-2">
                <FormattedMessage id="pricing.section1.description1" />
              </P>

              <P className="N0 space-outer-top-2">
                <FormattedMessage id="pricing.section1.description2" />
              </P>
            </Column>

            <Column className="col-12 col-lg-10 offset-lg-1 space-outer-top-8">
              <Tabs
                titles={[
                  <FormattedMessage key={0} id="pricing.tab1.title" />,
                  // <FormattedMessage key={1} id="pricing.tab2.title" />,
                  <FormattedMessage key={2} id="pricing.tab3.title" />,
                ]}
                content={[

                  <Card padding="48px" key={1}>
                    <div className="container-fluid">
                      <div className="row">
                      <div className="col-12 col-sm-12" style={{ textAlign: "center" }}>
                          <H3>
                            <FormattedMessage id="pricing.tab1.column1.title" />
                          </H3>

                          <P>
                            <FormattedMessage id="pricing.tab1.column1.description" />
                          </P>

                          <H1 className="space-outer-top-2 space-outer-bottom-0">
                            <FormattedMessage id="pricing.tab1.column1.price" />
                          </H1>
                          <div style={{ height: 32, marginBottom: 32 }} />

                          <A className="space-outer-top-2"
                            href={`${ studioLinkPrefix + (locale === "zh" ? "" : "/account/register") }`}
                            target="_blank"
                            style={{ borderBottom: "none" }}
                          >
                            <Button
                              title={<FormattedMessage id="pricing.tab1.column1.link" />}
                              hollow={true}
                            />
                          </A>

                          <List items={[
                            { title: <FormattedMessage id="pricing.tab1.column1.list.item1" /> },
                            { title: <FormattedMessage id="pricing.tab1.column1.list.item2" /> },
                            {
                              title: <FormattedMessage id="pricing.tab1.column1.list.item3" />,
                              tooltip: <FormattedMessage id="pricing.section3.tooltip.integrations" />
                            },
                            {
                              title: <FormattedMessage id="pricing.tab1.column1.list.item4" />,
                              tooltip: <FormattedMessage id="pricing.section3.tooltip.conversations" />
                            },
                            { title: <FormattedMessage id="pricing.tab1.column1.list.item5" /> },
                          ]} />
                        </div>

                        {/* <div className="col-12 col-sm-6 plan-right">
                          <H3>
                            <FormattedMessage id="pricing.tab1.column2.title" />
                          </H3>

                          <P>
                            <FormattedMessage id="pricing.tab1.column2.description" />
                          </P>

                          <H1 className="space-outer-top-2 space-outer-bottom-0">
                            <FormattedMessage id="pricing.tab1.column2.price" />
                          </H1>
                          <H4 className="space-outer-top-0 space-outer-bottom-4">
                            <FormattedMessage id="pricing.billed-monthly" />
                          </H4>

                          <Link to="/contact">
                            <Button
                              title={<FormattedMessage id="pricing.tab1.column2.link" />}
                              hollow={true}
                            />
                          </Link>

                          <List items={[
                            { title: <FormattedMessage id="pricing.tab1.column2.list.item1" /> },
                            { title: <FormattedMessage id="pricing.tab1.column2.list.item2" /> },
                            {
                              title: <FormattedMessage id="pricing.tab1.column2.list.item3" />,
                              tooltip: <FormattedMessage id="pricing.section3.tooltip.integrations" />
                            },
                            {
                              title: <FormattedMessage id="pricing.tab1.column2.list.item4" />,
                              tooltip: <FormattedMessage id="pricing.section3.tooltip.conversations" />
                            },
                            { title: <FormattedMessage id="pricing.tab1.column2.list.item5" /> }
                          ]} />
                        </div> */}

                        <div className="col-12 text-center pt-5">
                          <Link to="/pricing/#full-comparison">
                            <Button
                              title={<FormattedMessage id="pricing.section2.main-link" />}
                              hollow={true}
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Card>,

                  // <Card padding="48px" key={2}>
                  //   <div className="container-fluid">
                  //     <div className="row">
                  //       <div className="col-12 col-sm-6 plan-left">
                  //         <H3>
                  //           <FormattedMessage id="pricing.tab2.column1.title" />
                  //         </H3>

                  //         <P>
                  //           <FormattedMessage id="pricing.tab2.column1.description" />
                  //         </P>

                  //         <H1 className="space-outer-top-2 space-outer-bottom-0">
                  //           <FormattedMessage id="pricing.tab2.column1.price" />
                  //         </H1>
                  //         <H4 className="space-outer-top-0 space-outer-bottom-4">
                  //           <FormattedMessage id="pricing.billed-monthly" />
                  //         </H4>

                  //         <Link
                  //           to="/contact"
                  //           className="space-outer-top-2"
                  //         >
                  //           <Button
                  //             title={<FormattedMessage id="pricing.tab2.column1.link" />}
                  //             hollow={true}
                  //           />
                  //         </Link>

                  //         <List items={[
                  //           { title: <FormattedMessage id="pricing.tab2.column1.list.item1" /> },
                  //           { title: <FormattedMessage id="pricing.tab2.column1.list.item2" /> },
                  //           {
                  //             title: <FormattedMessage id="pricing.tab2.column1.list.item3" />,
                  //             tooltip: <FormattedMessage id="pricing.section3.tooltip.integrations" />
                  //           },
                  //           {
                  //             title: <FormattedMessage id="pricing.tab2.column1.list.item4" />,
                  //             tooltip: <FormattedMessage id="pricing.section3.tooltip.conversations" />
                  //           },
                  //           { title: <FormattedMessage id="pricing.tab2.column1.list.item5" /> },
                  //         ]} />
                  //       </div>

                  //       <div className="col-12 col-sm-6 plan-right">
                  //         <H3>
                  //           <FormattedMessage id="pricing.tab2.column2.title" />
                  //         </H3>

                  //         <P>
                  //           <FormattedMessage id="pricing.tab2.column2.description" />
                  //         </P>

                  //         <H1 className="space-outer-top-2 space-outer-bottom-0">
                  //           <FormattedMessage id="pricing.tab2.column2.price" />
                  //         </H1>
                  //         <H4 className="space-outer-top-0 space-outer-bottom-4">
                  //           <FormattedMessage id="pricing.billed-annually" />
                  //         </H4>

                  //         <Link to="/contact">
                  //           <Button
                  //             title={<FormattedMessage id="pricing.tab2.column2.link" />}
                  //             hollow={true}
                  //           />
                  //         </Link>

                  //         <List items={[
                  //           { title: <FormattedMessage id="pricing.tab2.column2.list.item1" /> },
                  //           { title: <FormattedMessage id="pricing.tab2.column2.list.item2" /> },
                  //           {
                  //             title: <FormattedMessage id="pricing.tab2.column2.list.item3" />,
                  //             tooltip: <FormattedMessage id="pricing.section3.tooltip.integrations" />
                  //           },
                  //           {
                  //             title: <FormattedMessage id="pricing.tab2.column2.list.item4" />,
                  //             tooltip: <FormattedMessage id="pricing.section3.tooltip.conversations" />
                  //           },
                  //           { title: <FormattedMessage id="pricing.tab2.column2.list.item5" /> },
                  //         ]} />
                  //       </div>

                  //       <div className="col-12 text-center pt-5">
                  //         <Link to="/pricing/#full-comparison">
                  //           <Button
                  //             title={<FormattedMessage id="pricing.section2.main-link" />}
                  //             hollow={true}
                  //           />
                  //         </Link>
                  //       </div>
                  //     </div>
                  //   </div>
                  // </Card>,
                  <Card padding="48px" key={3}>
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-12 col-sm-12" style={{ textAlign: "center" }}>
                          <H3>
                            <FormattedMessage id="pricing.tab3.column.title" />
                          </H3>

                          <P className="space-outer-bottom-3">
                            <FormattedMessage id="pricing.tab3.column.description" />
                          </P>

                          <Link to="/contact">
                            <Button
                              title={<FormattedMessage id="pricing.tab3.column.link" />}
                              hollow={true}
                            />
                          </Link>

                          <div style={{ textAlign: "left" }}>
                            <List items={[
                              { title: <FormattedMessage id="pricing.tab3.column.list.item1" /> },
                              { title: <FormattedMessage id="pricing.tab3.column.list.item2" /> },
                              { title: <FormattedMessage id="pricing.tab3.column.list.item3" /> },
                              { title: <FormattedMessage id="pricing.tab3.column.list.item4" /> }
                            ]} />
                          </div>
                        </div>

                        <div className="col-12 text-center pt-5">
                          <Link to="/pricing/#full-comparison">
                            <Button
                              title={<FormattedMessage id="pricing.section2.main-link" />}
                              hollow={true}
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Card>
                ]}
              />
            </Column>
          </Section>
        </BackgroundContainer>

        {/* Ready to See */}
        <Section
          partition="full-width"
          classes="P700-bg space-inner-top-6 space-inner-bottom-6"
        >
          <Column className="col-12 col-md-10 offset-md-1 text-center">
            <H2 className="N0">
              <FormattedMessage id="pricing.section2.heading" />
            </H2>
            <Link to="/contact" className="space-outer-top-2">
              <Button
                title={<FormattedMessage id="pricing.section2.link" />}
                inverse={true}
                className="large"
              />
            </Link>
          </Column>
        </Section>

        {/* Full Plan Comparison */}
        <Section
          partition="full-width"
          classes="space-inner-top-10 space-inner-bottom-10"
        >
          <Column className="col-12 text-center">
            <H2>
              <FormattedMessage id="pricing.section3.heading" />
            </H2>
          </Column>

          <Column className="col-12" id="full-comparison">
            <Table
              columnHeads={[
                {
                  title: <FormattedMessage id="pricing.section3.table.column2.title" />,
                  description: <FormattedMessage id="pricing.section3.table.column2.description" />,
                  link: `${ studioLinkPrefix + (locale === "zh" ? "" : "/account/register") }`,
                  linkTitle: <FormattedMessage id="pricing.section3.table.column2.link" />
                },
                // {
                //   title: <FormattedMessage id="pricing.section3.table.column3.title" />,
                //   description: <FormattedMessage id="pricing.section3.table.column3.description" />,
                //   link: "/contact",
                //   linkTitle: <FormattedMessage id="pricing.section3.table.column3.link" />
                // },
                // {
                //   title: <FormattedMessage id="pricing.section3.table.column4.title" />,
                //   description: <FormattedMessage id="pricing.section3.table.column4.description" />,
                //   link: "/contact",
                //   linkTitle: <FormattedMessage id="pricing.section3.table.column4.link" />
                // },
                // {
                //   title: <FormattedMessage id="pricing.section3.table.column5.title" />,
                //   description: <FormattedMessage id="pricing.section3.table.column5.description" />,
                //   link: "/contact",
                //   linkTitle: <FormattedMessage id="pricing.section3.table.column5.link" />
                // },
                {
                  title: <FormattedMessage id="pricing.section3.table.column6.title" />,
                  description: <FormattedMessage id="pricing.section3.table.column6.description" />,
                  link: "/contact",
                  linkTitle: <FormattedMessage id="pricing.section3.table.column6.link" />
                }
              ]}
              rows={[
                [
                  {
                    text: <FormattedMessage id="pricing.section3.table.column1.pricing" />,
                    isTitle: true,
                  },
                  {
                    children: (
                      <H3 className="price">
                        <FormattedMessage id="pricing.section3.table.column2.price" />
                        <span className="unit">
                          <FormattedMessage id="pricing.section3.table.column2.pricing" />
                        </span>
                      </H3>
                    )
                  },
                  // {
                  //   children: (
                  //     <H3 className="price">
                  //       <FormattedMessage id="pricing.section3.table.column3.price" />
                  //       <span className="unit">
                  //         <FormattedMessage id="pricing.section3.table.column3.pricing" />
                  //       </span>
                  //     </H3>
                  //   )
                  // },
                  // {
                  //   children: (
                  //     <H3 className="price">
                  //       <FormattedMessage id="pricing.section3.table.column4.price" />
                  //       <span className="unit">
                  //         <FormattedMessage id="pricing.section3.table.column4.pricing" />
                  //       </span>
                  //     </H3>
                  //   )
                  // },
                  // {
                  //   children: (
                  //     <H3 className="price">
                  //       <FormattedMessage id="pricing.section3.table.column5.price" />
                  //       <span className="unit">
                  //         <FormattedMessage id="pricing.section3.table.column5.pricing" />
                  //       </span>
                  //     </H3>
                  //   )
                  // },
                  {
                    children: (
                      <FormattedMessage id="pricing.section3.table.column6.pricing">
                        {(text) => (
                          <H3 className="price contact">
                            {text}
                          </H3>
                        )}
                      </FormattedMessage>
                    )
                  }
                ],
                [
                  {
                    text: <FormattedMessage id="pricing.section3.table.column1.term" />,
                    isTitle: true,
                  },
                  {
                    text: <FormattedMessage id="pricing.section3.table.column2.term" />,
                  },
                  // {
                  //   text: <FormattedMessage id="pricing.section3.table.column3.term" />,
                  // },
                  // {
                  //   text: <FormattedMessage id="pricing.section3.table.column4.term" />,
                  // },
                  // {
                  //   text: <FormattedMessage id="pricing.section3.table.column5.term" />,
                  // },
                  {
                    text: <FormattedMessage id="pricing.section3.table.column6.term" />,
                  }
                ],
                [
                  {
                    isSeparator: true,
                    text: <FormattedMessage id="pricing.section3.table.separator.chatbot-volume" />,
                  }
                ],
                [
                  {
                    text: <FormattedMessage id="pricing.section3.table.column1.availableBots" />,
                    isTitle: true,
                  },
                  {
                    text: <FormattedMessage id="pricing.section3.table.column2.availableBots" />
                  },
                  // {
                  //   text: <FormattedMessage id="pricing.section3.table.column3.availableBots" />
                  // },
                  // {
                  //   text: <FormattedMessage id="pricing.section3.table.column4.availableBots" />
                  // },
                  // {
                  //   text: <FormattedMessage id="pricing.section3.table.column5.availableBots" />
                  // },
                  {
                    text: <FormattedMessage id="pricing.section3.table.column6.availableBots" />
                  }
                ],
                [
                  {
                    text: <FormattedMessage id="pricing.section3.table.column1.additionalPrice" />,
                    isTitle: true,
                  },
                  {
                    text: <FormattedMessage id="pricing.section3.table.column2.additionalPrice" />
                  },
                  // {
                  //   text: <FormattedMessage id="pricing.section3.table.column3.additionalPrice" />
                  // },
                  // {
                  //   text: <FormattedMessage id="pricing.section3.table.column4.additionalPrice" />
                  // },
                  // {
                  //   text: <FormattedMessage id="pricing.section3.table.column5.additionalPrice" />
                  // },
                  {
                    text: <FormattedMessage id="pricing.section3.table.column6.additionalPrice" />
                  }
                ],
                [
                  {
                    isSeparator: true,
                    text: <FormattedMessage id="pricing.section3.table.separator.volume-rates" />,
                    separatorInfo: <FormattedMessage id="pricing.section3.tooltip.conversations" />
                  }
                ],
                [
                  {
                    text: <FormattedMessage id="pricing.section3.table.column1.monthlyConversations" />,
                    isTitle: true,
                  },
                  {
                    text: <FormattedMessage id="pricing.section3.table.column2.monthlyConversations" />
                  },
                  // {
                  //   text: <FormattedMessage id="pricing.section3.table.column3.monthlyConversations" />
                  // },
                  // {
                  //   text: <FormattedMessage id="pricing.section3.table.column4.monthlyConversations" />
                  // },
                  // {
                  //   text: <FormattedMessage id="pricing.section3.table.column5.monthlyConversations" />
                  // },
                  {
                    text: <FormattedMessage id="pricing.section3.table.column6.monthlyConversations" />
                  }
                ],
                [
                  {
                    text: <FormattedMessage id="pricing.section3.table.column1.pricePerConversation" />,
                    isTitle: true,
                  },
                  {
                    text: <FormattedMessage id="pricing.section3.table.column2.pricePerConversation" />
                  },
                  // {
                  //   text: <FormattedMessage id="pricing.section3.table.column3.pricePerConversation" />
                  // },
                  // {
                  //   text: <FormattedMessage id="pricing.section3.table.column4.pricePerConversation" />
                  // },
                  // {
                  //   text: <FormattedMessage id="pricing.section3.table.column5.pricePerConversation" />
                  // },
                  {
                    text: <FormattedMessage id="pricing.section3.table.column6.pricePerConversation" />
                  },
                ],
                [
                  {
                    text: <FormattedMessage id="pricing.section3.table.column1.speechToTextMessages" />,
                    isTitle: true,
                  },
                  {
                    text: <FormattedMessage id="pricing.section3.table.column2.speechToTextMessages" />
                  },
                  // {
                  //   text: <FormattedMessage id="pricing.section3.table.column3.speechToTextMessages" />
                  // },
                  // {
                  //   text: <FormattedMessage id="pricing.section3.table.column4.speechToTextMessages" />
                  // },
                  // {
                  //   text: <FormattedMessage id="pricing.section3.table.column5.speechToTextMessages" />
                  // },
                  {
                    text: <FormattedMessage id="pricing.section3.table.column6.speechToTextMessages" />
                  }
                ],
                [
                  {
                    text: <FormattedMessage id="pricing.section3.table.column1.pricePerSTM" />,
                    isTitle: true,
                  },
                  {
                    text: <FormattedMessage id="pricing.section3.table.column2.pricePerSTM" />,
                  },
                  // {
                  //   text: <FormattedMessage id="pricing.section3.table.column3.pricePerSTM" />,
                  // },
                  // {
                  //   text: <FormattedMessage id="pricing.section3.table.column4.pricePerSTM" />,
                  // },
                  // {
                  //   text: <FormattedMessage id="pricing.section3.table.column5.pricePerSTM" />,
                  // },
                  {
                    text: <FormattedMessage id="pricing.section3.table.column6.pricePerSTM" />,
                  },
                ],
                [
                  {
                    text: <FormattedMessage id="pricing.section3.table.column1.volumeDiscounts" />,
                    isTitle: true,
                  },
                  {
                    text: <FormattedMessage id="pricing.section3.table.column2.volumeDiscounts" />
                  },
                  // {
                  //   text: <FormattedMessage id="pricing.section3.table.column3.volumeDiscounts" />
                  // },
                  // {
                  //   text: <FormattedMessage id="pricing.section3.table.column4.volumeDiscounts" />
                  // },
                  // {
                  //   text: <FormattedMessage id="pricing.section3.table.column5.volumeDiscounts" />
                  // },
                  {
                    text: <FormattedMessage id="pricing.section3.table.column6.volumeDiscounts" />
                  }
                ],
                [
                  {
                    isSeparator: true,
                    text: <FormattedMessage id="pricing.section3.table.separator.channel-integrations" />,
                    separatorInfo: <FormattedMessage id="pricing.section3.tooltip.integrations" />
                  }
                ],
                [
                  {
                    text: <FormattedMessage id="pricing.section3.table.column1.integrationsIncluded" />,
                    isTitle: true,
                  },
                  {
                    text: <FormattedMessage id="pricing.section3.table.column2.integrationsIncluded" />
                  },
                  // {
                  //   text: <FormattedMessage id="pricing.section3.table.column3.integrationsIncluded" />
                  // },
                  // {
                  //   text: <FormattedMessage id="pricing.section3.table.column4.integrationsIncluded" />
                  // },
                  // {
                  //   text: <FormattedMessage id="pricing.section3.table.column5.integrationsIncluded" />
                  // },
                  {
                    text: <FormattedMessage id="pricing.section3.table.column6.integrationsIncluded" />
                  }
                ],
                [
                  {
                    text: <FormattedMessage id="pricing.section3.table.column1.speechToText" />,
                    isTitle: true,
                  },
                  {
                    children: (
                      <FontAwesomeIcon icon={faCheck} />
                    )
                  },
                  // {
                  //   children: (
                  //     <FontAwesomeIcon icon={faCheck} />
                  //   )
                  // },
                  // {
                  //   children: (
                  //     <FontAwesomeIcon icon={faCheck} />
                  //   )
                  // },
                  // {
                  //   children: (
                  //     <FontAwesomeIcon icon={faCheck} />
                  //   )
                  // },
                  {
                    children: (
                      <FontAwesomeIcon icon={faCheck} />
                    )
                  },
                ],
                [
                  {
                    text: <FormattedMessage id="pricing.section3.table.separator.support-levels" />,
                    isSeparator: true,
                    separatorInfo: <FormattedMessage id="pricing.section3.tooltip.support" />
                  }
                ],
                [
                  {
                    text: <FormattedMessage id="pricing.section3.table.column1.supportPlan" />,
                    isTitle: true,
                  },
                  {
                    children: (
                      <H3>
                        <FormattedMessage id="pricing.section3.table.column2.supportPlan" />
                      </H3>
                    )
                  },
                  // {
                  //   children: (
                  //     <H3>
                  //       <FormattedMessage id="pricing.section3.table.column3.supportPlan" />
                  //     </H3>
                  //   )
                  // },
                  // {
                  //   children: (
                  //     <H3>
                  //       <FormattedMessage id="pricing.section3.table.column4.supportPlan" />
                  //     </H3>
                  //   )
                  // },
                  // {
                  //   children: (
                  //     <H3>
                  //       <FormattedMessage id="pricing.section3.table.column5.supportPlan" />
                  //     </H3>
                  //   )
                  // },
                  {
                    children: (
                      <H3>
                        <FormattedMessage id="pricing.section3.table.column6.supportPlan" />
                      </H3>
                    )
                  }
                ],
                [
                  {
                    text: <FormattedMessage id="pricing.section3.table.separator.features" />,
                    isSeparator: true
                  }
                ],
                [
                  {
                    text: <FormattedMessage id="pricing.section3.table.column1.deployment" />,
                    isTitle: true
                  },
                  {
                    text: <FormattedMessage id="pricing.section3.table.column2.deployment" />
                  },
                  // {
                  //   text: <FormattedMessage id="pricing.section3.table.column3.deployment" />
                  // },
                  // {
                  //   text: <FormattedMessage id="pricing.section3.table.column4.deployment" />
                  // },
                  // {
                  //   text: <FormattedMessage id="pricing.section3.table.column5.deployment" />
                  // },
                  {
                    text: <FormattedMessage id="pricing.section3.table.column6.deployment" />
                  }
                ],
                [
                  {
                    text: <FormattedMessage id="pricing.section3.table.column1.studioUsers" />,
                    isTitle: true,
                  },
                  {
                    text: <FormattedMessage id="pricing.section3.table.column2.studioUsers" />
                  },
                  // {
                  //   text: <FormattedMessage id="pricing.section3.table.column3.studioUsers" />
                  // },
                  // {
                  //   text: <FormattedMessage id="pricing.section3.table.column4.studioUsers" />
                  // },
                  // {
                  //   text: <FormattedMessage id="pricing.section3.table.column5.studioUsers" />
                  // },
                  {
                    text: <FormattedMessage id="pricing.section3.table.column6.studioUsers" />
                  },
                ],
                [
                  {
                    text: <FormattedMessage id="pricing.section3.table.separator.professional-services" />,
                    isSeparator: true
                  }
                ],
                [
                  {
                    text: <FormattedMessage id="pricing.section3.table.column1.strategyAndRoadmap" />,
                    isTitle: true,
                  },
                  {
                    text: <FormattedMessage id="pricing.section3.table.column2.strategyAndRoadmap" />
                  },
                  // {
                  //   text: <FormattedMessage id="pricing.section3.table.column3.strategyAndRoadmap" />
                  // },
                  // {
                  //   text: <FormattedMessage id="pricing.section3.table.column4.strategyAndRoadmap" />
                  // },
                  // {
                  //   children: (
                  //     <FontAwesomeIcon icon={faCheck} />
                  //   )
                  // },
                  {
                    children: (
                      <FontAwesomeIcon icon={faCheck} />
                    )
                  },
                ],
                [
                  {
                    text: <FormattedMessage id="pricing.section3.table.column1.planningAndExecution" />,
                    isTitle: true,
                  },
                  {
                    text: <FormattedMessage id="pricing.section3.table.column2.planningAndExecution" />
                  },
                  // {
                  //   text: <FormattedMessage id="pricing.section3.table.column3.planningAndExecution" />
                  // },
                  // {
                  //   text: <FormattedMessage id="pricing.section3.table.column4.planningAndExecution" />
                  // },
                  // {
                  //   text: <FormattedMessage id="pricing.section3.table.column5.planningAndExecution" />
                  // },
                  {
                    children: (
                      <FontAwesomeIcon icon={faCheck} />
                    )
                  },
                ]
              ]}
            />
          </Column>
        </Section>

        {/* Ready for Demo */}
        <Section
          partition="full-width"
          classes="P700-bg space-inner-top-6 space-inner-bottom-6"
        >
          <Column className="col-12 text-center">
            <H2 className="N0">
              <FormattedMessage id="pricing.section4.heading" />
            </H2>
            <Link to="/contact" className="space-outer-top-2">
              <Button
                title={<FormattedMessage id="pricing.section4.link" />}
                inverse={true}
                className="large"
              />
            </Link>
          </Column>
        </Section>

        {/* Support Plans */}
        <Section
          partition="full-width"
          classes="space-inner-top-10 space-inner-bottom-10"
        >
          <Column className="col-12 text-center">
            <H2>
              <FormattedMessage id="pricing.section5.heading" />
            </H2>
          </Column>

          <Column className="col-12">
            <Table
              columnHeads={[
                {
                  title: <FormattedMessage id="pricing.section5.column2.title" />,
                  description: <FormattedMessage id="pricing.section3.table.column2.description" />,
                  link: `${ studioLinkPrefix + (locale === "zh" ? "" : "/account/register") }`,
                  linkTitle: <FormattedMessage id="pricing.section3.table.column2.link" />
                },
                {
                  title: <FormattedMessage id="pricing.section5.column3.title" />,
                  description: <FormattedMessage id="pricing.section3.table.column2.description" />,
                  link: `${ studioLinkPrefix + (locale === "zh" ? "" : "/account/register") }`,
                  linkTitle: <FormattedMessage id="pricing.section3.table.column2.link" />
                },
                {
                  title: <FormattedMessage id="pricing.section5.column4.title" />,
                  description: <FormattedMessage id="pricing.section3.table.column2.description" />,
                  link: `${ studioLinkPrefix + (locale === "zh" ? "" : "/account/register") }`,
                  linkTitle: <FormattedMessage id="pricing.section3.table.column2.link" />
                }
              ]}
              rows={[
                [
                  {
                    text: <FormattedMessage id="pricing.section5.table.column1.comms" />,
                    isTitle: true,
                  },
                  {
                    text: <FormattedMessage id="pricing.section5.table.column2.comms" />,
                  },
                  {
                    text: <FormattedMessage id="pricing.section5.table.column3.comms" />,
                  },
                  {
                    text: <FormattedMessage id="pricing.section5.table.column4.comms" />,
                  }
                ],
                [
                  {
                    text: <FormattedMessage id="pricing.section5.table.column1.supportHours" />,
                    isTitle: true,
                  },
                  {
                    text: <FormattedMessage id="pricing.section5.table.column2.supportHours" />,
                  },
                  {
                    text: <FormattedMessage id="pricing.section5.table.column3.supportHours" />,
                  },
                  {
                    text: <FormattedMessage id="pricing.section5.table.column4.supportHours" />,
                  }
                ],
                [
                  {
                    text: <FormattedMessage id="pricing.section5.table.column1.namedPersonel" />,
                    isTitle: true,
                  },
                  {
                    text: <FormattedMessage id="pricing.section5.table.column2.namedPersonel" />,
                  },
                  {
                    children: (
                      <FontAwesomeIcon icon={faCheck} />
                    )
                  },
                  {
                    children: (
                      <FontAwesomeIcon icon={faCheck} />
                    )
                  }
                ],
                [
                  {
                    text: <FormattedMessage id="pricing.section5.table.separator.response" />,
                    isSeparator: true
                  }
                ],
                [
                  {
                    text: <FormattedMessage id="pricing.section5.table.column1.generalIssues" />,
                    isTitle: true,
                  },
                  {
                    text: <FormattedMessage id="pricing.section5.table.column2.generalIssues" />,
                  },
                  {
                    text: <FormattedMessage id="pricing.section5.table.column3.generalIssues" />,
                  },
                  {
                    text: <FormattedMessage id="pricing.section5.table.column4.generalIssues" />,
                  }
                ],
                [
                  {
                    text: <FormattedMessage id="pricing.section5.table.column1.degradedService" />,
                    isTitle: true,
                  },
                  {
                    text: <FormattedMessage id="pricing.section5.table.column2.degradedService" />,
                  },
                  {
                    text: <FormattedMessage id="pricing.section5.table.column3.degradedService" />,
                  },
                  {
                    text: <FormattedMessage id="pricing.section5.table.column4.degradedService" />,
                  }
                ],
                [
                  {
                    text: <FormattedMessage id="pricing.section5.table.column1.downtime" />,
                    isTitle: true,
                  },
                  {
                    text: <FormattedMessage id="pricing.section5.table.column2.downtime" />,
                  },
                  {
                    text: <FormattedMessage id="pricing.section5.table.column3.downtime" />,
                  },
                  {
                    text: <FormattedMessage id="pricing.section5.table.column4.downtime" />,
                  }
                ],
                [
                  {
                    text: <FormattedMessage id="pricing.section5.table.separator.sla" />,
                    isSeparator: true
                  }
                ],
                [
                  {
                    text: <FormattedMessage id="pricing.section5.table.column1.uptime" />,
                    isTitle: true,
                  },
                  {
                    text: <FormattedMessage id="pricing.section5.table.column2.uptime" />,
                  },
                  {
                    text: <FormattedMessage id="pricing.section5.table.column3.uptime" />,
                  },
                  {
                    text: <FormattedMessage id="pricing.section5.table.column4.uptime" />,
                  }
                ]
              ]}
            />
          </Column>
        </Section>

        {/* Like What You See */}
        <Section
          partition="full-width"
          classes="P700-bg space-inner-top-6 space-inner-bottom-6"
        >
          <Column className="col-12 text-center">
            <H2 className="N0">
              <FormattedMessage id="pricing.section6.heading" />
            </H2>

            <A
              target="_blank"
              href={`${ studioLinkPrefix + (locale === "zh" ? "" : "/account/register") }`}
              className="space-outer-top-2"
            >
              <Button
                title={<FormattedMessage id="pricing.section6.link" />}
                inverse={true}
                className="large"
              />
            </A>
          </Column>
        </Section>

        {/* Features */}
        <Section
          partition="full-width"
          classes="space-inner-top-10 space-inner-bottom-10"
        >
          <Column className="col-12 text-center">
            <H2>
              <FormattedMessage id="pricing.section7.heading" />
            </H2>

            <P>
              <FormattedMessage id="pricing.section7.description" />
            </P>
          </Column>

          <Column className="col-12 col-md-6">
            <Feature
              icon={faChartNetwork}
              largeIcon={true}
              title={<FormattedMessage id="pricing.section7.feature1.title" />}
              description={<FormattedMessage id="pricing.section7.feature1.description" />}
            />
          </Column>

          <Column className="col-12 col-md-6">
            <Feature
              icon={faUserLock}
              largeIcon={true}
              title={<FormattedMessage id="pricing.section7.feature2.title" />}
              description={<FormattedMessage id="pricing.section7.feature2.description" />}
            />
          </Column>

          <Column className="col-12 col-md-6">
            <Feature
              icon={faHandshakeAlt}
              largeIcon={true}
              title={<FormattedMessage id="pricing.section7.feature3.title" />}
              description={<FormattedMessage id="pricing.section7.feature3.description" />}
            />
          </Column>

          <Column className="col-12 col-md-6">
            <Feature
              icon={faMegaphone}
              largeIcon={true}
              title={<FormattedMessage id="pricing.section7.feature4.title" />}
              description={<FormattedMessage id="pricing.section7.feature4.description" />}
            />
          </Column>

          <Column className="col-12 col-md-6">
            <Feature
              icon={faAnalytics}
              largeIcon={true}
              title={<FormattedMessage id="pricing.section7.feature5.title" />}
              description={<FormattedMessage id="pricing.section7.feature5.description" />}
            />
          </Column>

          <Column className="col-12 col-md-6">
            <Feature
              icon={faLineColumns}
              largeIcon={true}
              title={<FormattedMessage id="pricing.section7.feature6.title" />}
              description={<FormattedMessage id="pricing.section7.feature6.description" />}
            />
          </Column>

          <Column className="col-12 col-md-6">
            <Feature
              icon={faWandMagic}
              largeIcon={true}
              title={<FormattedMessage id="pricing.section7.feature7.title" />}
              description={<FormattedMessage id="pricing.section7.feature7.description" />}
            />
          </Column>

          <Column className="col-12 col-md-6">
            <Feature
              icon={faCommentsAlt}
              largeIcon={true}
              title={<FormattedMessage id="pricing.section7.feature8.title" />}
              description={<FormattedMessage id="pricing.section7.feature8.description" />}
            />
          </Column>
        </Section>

      </Layout>
    )
  }
}