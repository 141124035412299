import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Tooltip from "@stackchat/tooltip"
import * as React from "react"
import styled from "styled-components"

const Container = styled.div`
  background-color: #F6F7FC;
  padding-top: 16px;
  padding-bottom: 16px;

  p {
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    font-weight: 900;
    display: flex;
    align-items: center;
    justify-content: flex-start;


    svg {
      margin-left: 4px;
    }
  }
`

interface SeparatorProps {
  title: React.ReactNode
  info?: React.ReactNode
}

export default class Separator extends React.Component<SeparatorProps> {
  render() {
    const { title, info } = this.props
    return (
      <Container className="col-12">
        {info ? (
          <Tooltip content={info} position="mouse">
            <p>
              <span>{title}</span>
              <FontAwesomeIcon icon={faInfoCircle} color="#4c0099" />
            </p>
          </Tooltip>
        ) : (
            <p>
              <span>{title}</span>
            </p>
          )}
      </Container>
    )
  }
}