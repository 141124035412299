import styled from "styled-components"

export const TabsContainer = styled.div`
  max-width: 75%;

  @media only screen and (max-width: 576px) {
    max-width: unset;
  }
`

export const Tab = styled.div`
  height: 64px;
  background-color: #e4e8eb;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 150ms linear;
  cursor: pointer;
  font-size: 14px;
  font-weight: 900;
  text-transform: uppercase;

  &.active {
    background-color: #fff;
    cursor: default;
  }
`