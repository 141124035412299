import * as React from "react"
import styled from "styled-components"
import Separator from "./Separator"
import TableColumn from "./TableColumn"
import TableHeader from "./TableHeader"

const TableRow = styled.div`
  border: 1px solid #edeef5;
  border-bottom: none;

  &.last {
    border-bottom: 1px solid #edeef5;
  }
`

const TabsContainer = styled.div`
  border: 1px solid #edeef5;
  margin-bottom: 8px;
  border-radius: 8px;
  overflow: hidden;
`

const Tab = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 8px;
  padding-bottom: 8px;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 14px;
  cursor: pointer;

  &.active {
    cursor: default;
    background-color: #edeef5;
  }
`

export interface ColumnHead {
  title: React.ReactNode,
  description: React.ReactNode,
  linkTitle?: React.ReactNode
  link?: string
}

export interface Column {
  text?: React.ReactNode
  isTitle?: boolean
  isSeparator?: boolean
  separatorInfo?: React.ReactNode
  children?: React.ReactNode
}

interface TableProps {
  columnHeads: ColumnHead[]
  rows: Column[][]
}

interface TableState {
  currentIndex: number
}

export default class Table extends React.Component<TableProps, TableState> {
  state: TableState = {
    currentIndex: 0
  }

  setCurrentIndexTo = (index: number) => () => {
    this.setState(prevState => ({
      currentIndex: index
    }))
  }

  render() {
    const { columnHeads, rows } = this.props
    const { currentIndex } = this.state

    return (
      <div className="container-fluid">
        <TabsContainer className="row d-flex d-md-none">
          {columnHeads.map((columnHead, index) => (
            <Tab
              key={index}
              className={`col ${ index === currentIndex ? "active" : "" }`}
              onClick={this.setCurrentIndexTo(index)}>
              {columnHead.title}
            </Tab>
          ))}
        </TabsContainer>

        <TableHeader columns={columnHeads} currentIndex={currentIndex} />

        {rows.map((row, index) => {
          return (
            <TableRow className={`row ${ index === rows.length - 1 ? "last" : "" }`} key={index}>
              {row.map((column, columnIndex) => {
                if (column.isSeparator) {
                  return (
                    <Separator
                      key={columnIndex}
                      title={column.text!}
                      info={column.separatorInfo}
                    />
                  )
                }

                if (column.isTitle) {
                  return (
                    <TableColumn
                      key={columnIndex}
                      className="d-flex"
                      isTitle={true}
                      text={column.text}
                      width={columnHeads.length === 3 ? 3 : 2}
                    />
                  )
                }

                const isLastColumn = columnIndex === row.length - 1
                const classes =
                  columnIndex - 1 === currentIndex ?
                    `d-flex ${ isLastColumn && "last" }` :
                    `d-none d-md-flex ${ isLastColumn && "last" }`
                return (
                  <TableColumn
                    key={columnIndex}
                    className={classes}
                    width={Math.floor((12 - (columnHeads.length === 3 ? 3 : 2)) / (row.length - 1))}
                    text={column.text}
                    isTitle={column.isTitle}>
                    {column.children}
                  </TableColumn>
                )
              })}
            </TableRow>
          )
        })}
      </div>
    )
  }
}