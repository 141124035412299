import * as React from "react"
import { Column, Row, SectionContainer } from "./styled"

type Partition = "full-width" | "50-50" | "60-40"

interface SectionProps {
  id?: string
  classes?: string
  partition: Partition
  inverse?: boolean
  leftChild?: React.ReactNode
  rightChild?: React.ReactNode
  style?: React.CSSProperties
}

export default class Section extends React.Component<SectionProps> {
  render() {
    const {
      id,
      partition,
      children,
      leftChild,
      rightChild,
      classes = "",
      inverse = false,
      style = {}
    } = this.props

    if (partition === "full-width") {
      return (
        <SectionContainer id={id} className={`container-fluid ${classes}`} style={style}>
          <div className="container">
            <Row className="row">
              {children}
            </Row>
          </div>
        </SectionContainer>
      )
    }

    if (partition === "60-40") {
      return (
        <SectionContainer id={id} className={`container-fluid ${classes}`} style={style}>
          <div className="container">
            <Row className="row">
              <Column className={`col-12 col-sm-5 ${inverse ? "d-block d-sm-none" : "d-block"}`}>
                {leftChild}
              </Column>

              <Column className={`col-12 col-sm-7`}>
                {rightChild}
              </Column>

              {inverse ? (
                <Column className="col-12 col-sm-5 d-none d-sm-block">
                  {leftChild}
                </Column>
              ) : null}
            </Row>
          </div>
        </SectionContainer>
      )
    }

    return (
      <SectionContainer id={id} className={`container-fluid ${classes}`} style={style}>
        <div className="container">
          <Row className="row">
            <Column className={`col-12 col-sm-6 ${inverse ? "d-block d-sm-none" : "d-block"}`}>
              {leftChild}
            </Column>

            <Column className="col-12 col-sm-6">
              {rightChild}
            </Column>

            {inverse ? (
              <Column className="col-12 col-sm-6 d-none d-sm-block">
                {leftChild}
              </Column>
            ) : null}
          </Row>
        </div>
      </SectionContainer>
    )
  }
}

export { Column }