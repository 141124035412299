import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Tooltip from "@stackchat/tooltip"
import * as React from "react"
import styled from "styled-components"

const StyledList = styled.ul`
  list-style: disc;
  margin-top: 24px;
  padding-left: 0;
  font-size: 24px;
`

const ListItem = styled.li`
  line-height: 32px;
  font-size: 24px;
  display: list-item;
  text-align: left;

  svg {
    margin-left: 4px;
  }
`

const StyledSpan = styled.span`
  font-size: 22px;
  line-height: 32px;
  @media only screen and (max-width: 767.98px) {
    font-size: 18px;
    line-height: 24px;
  }
`

const TooltipContent = styled.div`
  padding: 4px 8px;
`

interface ListProps {
  items: Array<{
    title: React.ReactNode,
    tooltip?: React.ReactNode
  }>
}

export default class List extends React.Component<ListProps> {
  render() {
    const { items } = this.props

    return (
      <StyledList>
        {items.map((item, index) => {
          return (
            <ListItem key={index}>
              {item.tooltip ? (
                <Tooltip
                  content={(
                    <TooltipContent>
                      <StyledSpan>{item.tooltip}</StyledSpan>
                    </TooltipContent>
                  )}
                  backgroundColor="#4c0099"
                  position="right"
                >
                  <StyledSpan>
                    {item.title}

                    <FontAwesomeIcon icon={faInfoCircle} size="xs" color="#4c0099" />
                  </StyledSpan>
                </Tooltip>
              ) : (
                  <StyledSpan>
                    {item.title}
                  </StyledSpan>
                )}
            </ListItem>
          )
        })}
      </StyledList>
    )
  }
}