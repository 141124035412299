import * as React from "react"
import styled, { css } from "styled-components"

const Container = styled.div`
  width: 100%;
  background-color: #fff;
  box-shadow: 0 0 4px 0px #d8d8d8;
  ${(props: { padding: string }) => css`
    padding: ${props.padding};
  `}

  @media only screen and (max-width: 768px) {
    padding: 32px;
  }

  @media only screen and (max-width: 576px) {
    padding: 24px;
  }
`

export default class Card extends React.Component<{ padding?: string }> {
  render() {
    return (
      <Container padding={this.props.padding || ""}>
        {this.props.children}
      </Container>
    )
  }
}